.FileField {
  display: flex;
  flex-direction: column;
}

.FileField__Preview {
  position: relative;
  padding-top: 56.25%;
}

.FileField__Preview__Img {
  background-color: #f1f1f1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
